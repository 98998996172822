import React, { FC, useEffect, useState } from 'react';
import { Loader, Modal, Stack, Text, Title } from '@mantine/core';
import { useIdleTimer } from 'react-idle-timer';
import { constVoid } from 'fp-ts/function';
import { SharedButton } from '@root/styles/shared';
import { useAuthContext } from '@modules/auth/context';
import * as option from 'fp-ts/Option';
//30 minutes
const TIMEOUT_MS = 1000 * 60 * 30;
const PROMPT_TIMEOUT_MS = 10_000;

const IdleAutoLogoutIndicator: FC = () => {
  const [opened, setOpened] = useState(false);
  const [remaining, setRemaining] = useState<number>(TIMEOUT_MS);
  const { logout, profile } = useAuthContext();

  const { getRemainingTime, activate, start, pause } = useIdleTimer({
    timeout: TIMEOUT_MS,
    promptBeforeIdle: PROMPT_TIMEOUT_MS,
    startManually: true,
    startOnMount: false,
    onPrompt: () => {
      setOpened(true);
    },
    onActive: () => {
      setOpened(false);
    },
    onIdle: async () => {
      setOpened(false);
      await logout();
    },
    throttle: 500,
  });
  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });
  useEffect(() => {
    if (option.isSome(profile)) {
      start();
    } else {
      pause();
    }
  }, [pause, profile, start]);
  const seconds = remaining > 1 ? 'secondes' : 'seconde';

  return (
    <Modal size="md" opened={opened} onClose={constVoid} withCloseButton={false} centered>
      <Stack p="md" align="center">
        <Title size="h3" align="center">
          Êtes-vous toujours là ?
        </Title>
        <Text align="center">
          Vous allez être déconnecté dans {remaining} {seconds}
        </Text>
        <SharedButton onClick={() => activate()}>Je suis encore là</SharedButton>
        <Loader size="lg" />
      </Stack>
    </Modal>
  );
};

export default IdleAutoLogoutIndicator;
