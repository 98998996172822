import React, { forwardRef } from 'react';
import { Link, To } from 'react-router-dom';

import { Button as MantineButton, ButtonProps as MantineButtonProps, createPolymorphicComponent } from '@mantine/core';
import {
  ArrowBarToDown,
  ArrowUpRight,
  Check,
  ChevronRight,
  Door,
  MailForward,
  Pencil,
  Photo,
  Plus,
  Router,
  Trash,
  X,
} from 'tabler-icons-react';

export type ButtonType =
  | 'add'
  | 'save'
  | 'next'
  | 'door'
  | 'download'
  | 'mail'
  | 'check'
  | 'uncheck'
  | 'cancel'
  | 'delete'
  | 'connect'
  | 'modify'
  | 'send'
  | 'image';

const buttonPropsByType: Record<ButtonType, MantineButtonProps> = {
  add: {
    children: 'Ajouter',
    leftIcon: <Plus width={18} />,
  },
  save: {
    children: 'Enregistrer',
    size: 'md',
  },
  next: {
    children: 'Suivant',
    leftIcon: <ChevronRight width={18} />,
    size: 'md',
  },
  door: {
    children: 'Ouvrir la porte',
    leftIcon: <Door width={18} />,
    variant: 'light',
    size: 'md',
  },
  download: {
    children: 'Télécharger',
    leftIcon: <ArrowBarToDown width={18} />,
    color: 'cyan',
    variant: 'light',
    size: 'md',
  },
  mail: {
    children: 'Envoyer un mail',
    leftIcon: <MailForward width={18} />,
    color: 'cyan',
    variant: 'light',
    size: 'md',
  },
  check: {
    children: 'Activer',
    leftIcon: <Check width={18} />,
    variant: 'light',
    size: 'md',
  },
  uncheck: {
    children: 'Désactiver',
    leftIcon: <X width={18} />,
    color: 'red',
    variant: 'light',
    size: 'md',
  },
  delete: {
    color: 'pink.6',
    children: 'Supprimer',
    leftIcon: <Trash width={18} />,
    variant: 'light',
    size: 'md',
  },
  cancel: {
    children: 'Annuler',
    color: 'gray',
    variant: 'light',
    size: 'md',
  },
  connect: {
    color: 'primary',
    children: 'Connecter',
    leftIcon: <Router width={18} />,
    variant: 'light',
    radius: 'xl',
  },
  send: {
    color: 'cyan',
    children: 'Envoyer',
    leftIcon: <ArrowUpRight width={18} />,
    variant: 'light',
    radius: 'xl',
  },
  modify: {
    color: 'cyan',
    children: 'Modifier',
    leftIcon: <Pencil width={18} />,
    variant: 'light',
    radius: 'xl',
  },
  image: {
    color: 'gray',
    children: 'Sélectionner',
    leftIcon: <Photo width={18} />,
    variant: 'light',
  },
};

export interface ButtonProps extends MantineButtonProps {
  btnType?: ButtonType;
  show?: boolean;
}

const _SharedButton = forwardRef<HTMLButtonElement, ButtonProps>(
  ({ btnType, show, disabled, loading, className, ...buttonProps }, ref) => {
    if (show === false) {
      return null;
    }

    const buttonTypeProps = btnType ? buttonPropsByType[btnType] : {};

    const buttonDisabled = loading || disabled;

    return (
      <MantineButton
        ref={ref}
        className={`${className ?? ''} ${btnType ?? ''}`}
        {...buttonTypeProps}
        {...buttonProps}
        disabled={buttonDisabled}
        loading={loading}
      />
    );
  },
);

export const SharedButton = createPolymorphicComponent<'button', ButtonProps>(_SharedButton);

export interface SharedButtonLinkProps extends ButtonProps {
  to: To;
  state?: any;
}

export const SharedButtonLink = forwardRef<HTMLAnchorElement, SharedButtonLinkProps>(
  ({ to, state, btnType, ...buttonProps }, ref) => {
    return (
      <SharedButton
        ref={ref}
        component={Link}
        to={to}
        btnType={btnType}
        state={btnType === 'cancel' ? { ...state, ignorePrevent: true } : state}
        {...buttonProps}
      />
    );
  },
);
